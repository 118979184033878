import React, { useState, useEffect, useMemo } from "react";
import api from "./services/api";

import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";

import { ptBR } from "@material-ui/core/locale";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import ColorModeContext from "./layout/themeContext";
import { ActiveMenuProvider } from "./context/ActiveMenuContext";
import Favicon from "react-favicon";
import { getBackendUrl } from "./config";
import Routes from "./routes";
import defaultLogoLight from "./assets/logo.png";
import defaultLogoDark from "./assets/logo-black.png";
import defaultLogoFavicon from "./assets/favicon.ico";
import useSettings from "./hooks/useSettings";

const queryClient = new QueryClient();

const App = () => {
    const [locale, setLocale] = useState();
    const appColorLocalStorage = localStorage.getItem('primaryColorLight') || localStorage.getItem('primaryColorDark') || '#31A457';
    const appNameLocalStorage = localStorage.getItem('appName') || '';
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const preferredTheme = window.localStorage.getItem("preferredTheme");
    const [mode, setMode] = useState(preferredTheme ? preferredTheme : prefersDarkMode ? "dark" : "light");
    const [primaryColorLight, setPrimaryColorLight] = useState(appColorLocalStorage);
    const [primaryColorDark, setPrimaryColorDark] = useState(appColorLocalStorage);
    const [appLogoLight, setAppLogoLight] = useState("");
    const [appLogoDark, setAppLogoDark] = useState("");
    const [appLogoFavicon, setAppLogoFavicon] = useState("");
    const [appName, setAppName] = useState(appNameLocalStorage);
    const { getPublicSetting } = useSettings();

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
            },
            setPrimaryColorLight: (color) => {
                setPrimaryColorLight(color);
            },
            setPrimaryColorDark: (color) => {
                setPrimaryColorDark(color);
            },
            setAppLogoLight: (file) => {
                setAppLogoLight(file);
            },
            setAppLogoDark: (file) => {
                setAppLogoDark(file);
            },
            setAppLogoFavicon: (file) => {
                setAppLogoFavicon(file);
            },
            setAppName: (name) => {
                setAppName(name);
            }
        }),
        []
    );

    const theme = useMemo(() => createTheme(
        {
            scrollbarStyles: {
                "&::-webkit-scrollbar": {
                    width: '8px',
                    height: '8px',
                },
                "&::-webkit-scrollbar-thumb": {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: mode === "light" ? primaryColorLight : primaryColorDark,
                    borderRadius: "0 10px 10px 0",
                },
            },
            scrollbarStylesSoft: {
                "&::-webkit-scrollbar": {
                    width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: mode === "light" ? "#F3F3F3" : "#333333",
                },
            },
            palette: {
                type: mode,
                primary: { main: mode === "light" ? primaryColorLight : primaryColorDark },
                error: { main: "#f44336" },
                warning: { main: "#FFA000" },
                success: { main: "#4CAF50" },
                info: { main: "#2196F3" },
                textPrimary: mode === "light" ? primaryColorLight : primaryColorDark,
                borderPrimary: mode === "light" ? primaryColorLight : primaryColorDark,
                dark: { main: mode === "light" ? "#333333" : "#F3F3F3" },
                light: { main: mode === "light" ? "#F3F3F3" : "#333333" },
                fontColor: mode === "light" ? primaryColorLight : primaryColorDark,
                tabHeaderBackground: mode === "light" ? "#EEE" : "#666",
                optionsBackground: mode === "light" ? "#fafafa" : "#333",
                fancyBackground: mode === "light" ? "#fafafa" : "#333",
                total: mode === "light" ? "#fff" : "#222",
                messageIcons: mode === "light" ? "grey" : "#F3F3F3",
                inputBackground: mode === "light" ? "#FFFFFF" : "#333",
                barraSuperior: mode === "light" ? primaryColorLight : "#666",
            },
            mode,
            appLogoLight,
            appLogoDark,
            appLogoFavicon,
            appName,
            calculatedLogoDark: () => {
                if (appLogoDark === defaultLogoDark && appLogoLight !== defaultLogoLight) {
                    return appLogoLight;
                }
                return appLogoDark;
            },
            calculatedLogoLight: () => {
                if (appLogoDark !== defaultLogoDark && appLogoLight === defaultLogoLight) {
                    return appLogoDark;
                }
                return appLogoLight;
            }
        },
        locale
    ), [appLogoLight, appLogoDark, appLogoFavicon, appName, locale, mode, primaryColorDark, primaryColorLight]);

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale =
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem("preferredTheme", mode);
    }, [mode]);

    useEffect(() => {
        getPublicSetting("primaryColorLight")
            .then((color) => { setPrimaryColorLight(color || "#0000FF") })
            .catch((error) => { console.log("Error reading setting", error); });
        getPublicSetting("primaryColorDark")
            .then((color) => { setPrimaryColorDark(color || "#39ACE7") })
            .catch((error) => { console.log("Error reading setting", error); });
        getPublicSetting("appLogoLight")
            .then((file) => { setAppLogoLight(file ? (getBackendUrl() + "/public/" + file) : defaultLogoLight) }, (_) => { })
            .catch((error) => { console.log("Error reading setting", error); });
        getPublicSetting("appLogoDark")
            .then((file) => { setAppLogoDark(file ? (getBackendUrl() + "/public/" + file) : defaultLogoDark) })
            .catch((error) => { console.log("Error reading setting", error); });
        getPublicSetting("appLogoFavicon")
            .then((file) => { setAppLogoFavicon(file ? (getBackendUrl() + "/public/" + file) : defaultLogoFavicon) })
            .catch((error) => { console.log("Error reading setting", error); });
        getPublicSetting("appName").then((name) => { setAppName(name || "Automatus") })
            .catch((error) => { console.log("Error reading setting", error); setAppName("Automatus") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Injetar variáveis CSS dinamicamente
    useEffect(() => {
        const root = document.documentElement;
        root.style.setProperty('--primaryColor', mode === "light" ? primaryColorLight : primaryColorDark);
    }, [primaryColorLight, primaryColorDark, mode]);

    useEffect(() => {
        async function fetchVersionData() {
            try {
                const response = await api.get("/version");
                const { data } = response;
                window.localStorage.setItem("frontendVersion", data.version);

            } catch (error) {
                console.log("Error fetching data", error);
            }
        }
        fetchVersionData();
    }, []);

    return (
        <>
            <Favicon url={((appLogoFavicon) ? getBackendUrl() + "/public/" + theme.appLogoFavicon : defaultLogoFavicon)} />
            <ColorModeContext.Provider value={{ appLogoLight, appLogoDark, primaryColorLight, primaryColorDark, mode, colorMode }}>
                <ThemeProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <ActiveMenuProvider>
                            <Routes />
                        </ActiveMenuProvider>
                    </QueryClientProvider>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
};

export default App;
