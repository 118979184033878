import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { green, grey } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import ContactTag from "../ContactTag";
import ConnectionIcon from "../ConnectionIcon";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import ShowTicketOpen from "../ShowTicketOpenModal";
import { isNil } from "lodash";
import { toast } from "react-toastify";
import { Done, HighlightOff, Replay, SwapHoriz } from "@material-ui/icons";
import useCompanySettings from "../../hooks/useSettings/companySettings";
import {
  Avatar,
  Badge,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  ButtonGroup,
  Button,
  Chip,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    height: "70px",
  },

  pendingTicket: {
    cursor: "unset",
  },

  queueTag: {
    background: "#FCFCFC",
    color: "#000",
    marginRight: 1,
    padding: 1,
    fontWeight: "bold",
    borderRadius: 3,
    fontSize: "0.5em",
    whiteSpace: "nowrap",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  newMessagesCount: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -10,
    left: -15,
    color: "green",
    fontWeight: "bold",
    borderRadius: 0,
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  connectionTag: {
    background: "green",
    color: "#FFF",
    marginRight: 1,
    padding: 1,
    fontWeight: "bold",
    borderRadius: 3,
    fontSize: "0.6em",
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
    fontWeight: "bold",
    color: theme.mode === "light" ? "black" : "white",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -20,
    marginRight: "1px",
    color: theme.mode === "light" ? "black" : grey[400],
  },

  lastMessageTimeUnread: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    top: -20,
    color: "green",
    fontWeight: "bold",
    marginRight: "1px",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    paddingRight: "0%",
    marginLeft: "5px",
    color: theme.mode === "light" ? "black" : grey[400],
  },

  contactLastMessageUnread: {
    paddingRight: 20,
    fontWeight: "bold",
    color: theme.mode === "light" ? "black" : grey[400],
    width: "50%",
  },

  badgeStyle: {
    color: "white",
    backgroundColor: green[500],
  },

  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "45px",
  },

  buttonGroup: {
    "& .MuiButton-text": {
      borderColor: theme.mode === "light" ? "#31A457" : "#FFF",
      borderRadius: "10px",
      "&:hover": {
        borderColor: "#31A457",
      },
    },
  },

  button: {
    minWidth: "18px",
    height: "18px",
    color: theme.mode === "light" ? "#31A457" : "#FFF",
    "&:hover": {
      backgroundColor: "rgba(189, 189, 189, 0.5) !important",
    },
  },

  iconSize: {
    fontSize: "1rem",
    color: theme.mode === "light" ? "#31A457" : "#FFF",
  },

  ticketQueueColor: {
    flex: "none",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13,
  },

  secondaryContentSecond: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },

  secondaryContentSecond1: {
    display: "flex",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignContent: "flex-start",
    alignItems: "center",
    marginTop: "3px",
  },

  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0,
  },

  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3,
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },

  connectionIcon: {
    marginRight: theme.spacing(1),
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
  },
}));

const TicketListItemCustom = ({ setTabOpen, ticket }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [
    acceptTicketWithouSelectQueueOpen,
    setAcceptTicketWithouSelectQueueOpen,
  ] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [userTicketOpen, setUserTicketOpen] = useState("");
  const [queueTicketOpen, setQueueTicketOpen] = useState("");

  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);

  const { get: getSetting } = useCompanySettings();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleOpenAcceptTicketWithouSelectQueue = useCallback(() => {
    setAcceptTicketWithouSelectQueueOpen(true);
  }, []);

  const handleCloseTicket = async (id) => {
    const setting = await getSetting({
      column: "requiredTag",
    });

    if (setting.requiredTag === "enabled") {
      try {
        const contactTags = await api.get(`/contactTags/${ticket.contact.id}`);
        if (!contactTags.data.tags) {
          toast.warning(i18n.t("messagesList.header.buttons.requiredTag"));
        } else {
          await api.put(`/tickets/${id}`, {
            status: "closed",
            userId: user?.id || null,
          });

          if (isMounted.current) {
            setLoading(false);
          }

          history.push(`/tickets/`);
        }
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
    } else {
      setLoading(true);
      try {
        await api.put(`/tickets/${id}`, {
          status: "closed",
          userId: user?.id || null,
        });
      } catch (err) {
        setLoading(false);
        toastError(err);
      }
      if (isMounted.current) {
        setLoading(false);
      }

      history.push(`/tickets/`);
    }
  };

  const handleCloseIgnoreTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id || null,
        sendFarewellMessage: false,
        amountUsedBotQueues: 0,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/`);
  };

  const truncate = (str, len) => {
    if (!isNil(str)) {
      if (str.length > len) {
        return str.substring(0, len) + "...";
      }
      return str;
    }
  };

  const handleCloseTransferTicketModal = useCallback(() => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  }, []);

  const handleOpenTransferModal = () => {
    setLoading(true);
    setTransferTicketModalOpen(true);
    if (isMounted.current) {
      setLoading(false);
    }
    handleSelectTicket(ticket);
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleAcepptTicket = async (id) => {
    setLoading(true);
    try {
      const otherTicket = await api.put(`/tickets/${id}`, {
        status:
          ticket.isGroup && ticket.channel === "whatsapp" ? "group" : "open",
        userId: user?.id,
      });

      if (otherTicket.data.id !== ticket.id) {
        if (otherTicket.data.userId !== user?.id) {
          setOpenAlert(true);
          setUserTicketOpen(otherTicket.data.user.name);
          setQueueTicketOpen(otherTicket.data.queue.name);
        } else {
          setLoading(false);
          setTabOpen(ticket.isGroup ? "group" : "open");
          handleSelectTicket(otherTicket.data);
          history.push(`/tickets/${otherTicket.uuid}`);
        }
      } else {
        let setting;

        try {
          setting = await getSetting({
            column: "sendGreetingAccepted",
          });
        } catch (err) {
          toastError(err);
        }

        if (
          setting.sendGreetingAccepted === "enabled" &&
          (!ticket.isGroup || ticket.whatsapp?.groupAsTicket === "enabled")
        ) {
          handleSendMessage(ticket.id);
        }
        if (isMounted.current) {
          setLoading(false);
        }

        setTabOpen(ticket.isGroup ? "group" : "open");
        handleSelectTicket(ticket);
        history.push(`/tickets/${ticket.uuid}`);
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleSendMessage = async (id) => {
    let setting;

    try {
      setting = await getSetting({
        column: "greetingAcceptedMessage",
      });
    } catch (err) {
      toastError(err);
    }

    const msg = `${setting.greetingAcceptedMessage}`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message);
    } catch (err) {
      toastError(err);
    }
  };

  const handleCloseAlert = useCallback(() => {
    setOpenAlert(false);
    setLoading(false);
  }, []);

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  return (
    <React.Fragment key={ticket.id}>
      {openAlert && (
        <ShowTicketOpen
          isOpen={openAlert}
          handleClose={handleCloseAlert}
          user={userTicketOpen}
          queue={queueTicketOpen}
        />
      )}
      {acceptTicketWithouSelectQueueOpen && (
        <AcceptTicketWithouSelectQueue
          modalOpen={acceptTicketWithouSelectQueueOpen}
          onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
          ticketId={ticket.id}
          ticket={ticket}
        />
      )}
      {transferTicketModalOpen && (
        <TransferTicketModalCustom
          modalOpen={transferTicketModalOpen}
          onClose={handleCloseTransferTicketModal}
          ticketid={ticket.id}
          ticket={ticket}
        />
      )}

      <ListItemButton
        dense
        onClick={(e) => {
          handleSelectTicket(ticket);
        }}
        selected={ticketId && ticketId === ticket.uuid}
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        {ticket.status !== "group" && (
          <Tooltip
            arrow
            placement="right"
            title={
              ticket.queueId ? ticket.queue?.name.toUpperCase() : "SEM FILA"
            }
          >
            <span
              style={{
                backgroundColor: ticket.queueId
                  ? ticket.queue?.color || "#7c7c7c"
                  : "#7c7c7c",
                color: theme.mode === "light" ? "#FFF" : "#FFF",
                fontSize: "0.75rem",
              }}
              className={classes.ticketQueueColor}
            ></span>
          </Tooltip>
        )}
        <ListItemAvatar sx={{ marginRight: "5px" }}>
          <Avatar
            sx={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
            }}
            src={`${ticket?.contact?.urlPicture}`}
          />
        </ListItemAvatar>
        <Badge
          className={classes.newMessagesCount}
          badgeContent={ticket.unreadMessages}
          classes={{
            badge: classes.badgeStyle,
          }}
        />
        <ListItemText
          disableTypography
          primary={
            <span className={classes.contactNameWrapper}>
              <Typography noWrap component="span" variant="body2">
                {ticket.isGroup && ticket.channel === "whatsapp" && (
                  <GroupIcon
                    fontSize="small"
                    style={{
                      color: theme.mode === "light" ? "#31A457" : "#FFF",
                      marginBottom: "-6px",
                    }}
                  />
                )}{" "}
                &nbsp;
                {ticket.channel && (
                  <Tooltip title={ticket.whatsapp?.name.toUpperCase()}>
                    <span>
                      <ConnectionIcon
                        width="20"
                        height="20"
                        className={classes.connectionIcon}
                        connectionType={ticket.channel}
                      />
                    </span>
                  </Tooltip>
                )}
                {truncate(ticket.contact?.name, 60)}
              </Typography>
            </span>
          }
          secondary={
            <span className={classes.contactNameWrapper}>
              <Typography
                className={
                  Number(ticket.unreadMessages) > 0
                    ? classes.contactLastMessageUnread
                    : classes.contactLastMessage
                }
                noWrap
                component="span"
                variant="body2"
              >
                {ticket.lastMessage ? (
                  <>
                    {ticket.lastMessage.includes("data:image/png;base64") ? (
                      <MarkdownWrapper>Localização</MarkdownWrapper>
                    ) : (
                      <>
                        {" "}
                        {ticket.lastMessage.includes("BEGIN:VCARD") ? (
                          <MarkdownWrapper>Contato</MarkdownWrapper>
                        ) : (
                          <MarkdownWrapper>
                            {truncate(ticket.lastMessage, 40)}
                          </MarkdownWrapper>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <br />
                )}
                <span className={classes.secondaryContentSecond1}>
                  {ticket.status === "pending" && !ticket.user ? (
                    <Tooltip title={i18n.t("messageVariablesPicker.vars.user")}>
                      <Chip
                        label={
                          <span style={{ position: "relative", top: "2px" }}>
                            {ticket.user
                              ? ticket.user.name.toUpperCase()
                              : "SEM ATENDENTE"}
                          </span>
                        }
                        icon={
                          <PersonIcon
                            style={{
                              fontSize: "14px",
                              color: theme.mode === "light" ? "#000" : "#FFF",
                            }}
                          />
                        }
                        style={{
                          backgroundColor:
                            theme.mode === "light" ? "#E2E2E2" : "#666666",
                          color: theme.mode === "light" ? "#000" : "#FFF",
                          boxShadow: "none",
                          height: "14px",
                          minWidth: "14px",
                          fontSize: "0.55rem",
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "center",
                          transition:
                            "background-color 0.5s ease, color 0.5s ease",
                        }}
                      />
                    </Tooltip>
                  ) : ticket.user ? (
                    <Tooltip title={i18n.t("messageVariablesPicker.vars.user")}>
                      <Chip
                        label={
                          <span style={{ position: "relative", top: "2px" }}>
                            {ticket.user
                              ? ticket.user.name.toUpperCase()
                              : "SEM ATENDENTE"}
                          </span>
                        }
                        icon={
                          <PersonIcon
                            style={{
                              fontSize: "14px",
                              color: theme.mode === "light" ? "#000" : "#FFF",
                            }}
                          />
                        }
                        style={{
                          backgroundColor:
                            theme.mode === "light" ? "#E2E2E2" : "#666666",
                          color: theme.mode === "light" ? "#000" : "#FFF",
                          boxShadow: "none",
                          height: "14px",
                          minWidth: "14px",
                          fontSize: "0.55rem",
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "center",
                          transition:
                            "background-color 0.5s ease, color 0.5s ease",
                        }}
                      />
                    </Tooltip>
                  ) : null}
                </span>

                <span className={classes.secondaryContentSecond}>
                  {ticket.tags?.map((tag) => {
                    return (
                      <ContactTag
                        tag={tag}
                        key={`ticket-contact-tag-${ticket.id}-${tag.id}`}
                      />
                    );
                  })}
                </span>
              </Typography>
            </span>
          }
        />
        <ListItemSecondaryAction>
          {ticket.lastMessage && (
            <>
              <Typography
                className={
                  Number(ticket.unreadMessages) > 0
                    ? classes.lastMessageTimeUnread
                    : classes.lastMessageTime
                }
                component="span"
                variant="body2"
                // color="textSecondary"
              >
                {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                  <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                ) : (
                  <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                )}
              </Typography>

              <br />
            </>
          )}
        </ListItemSecondaryAction>
        <ListItemSecondaryAction>
          <div className={classes.buttonsContainer}>
            <ButtonGroup
              className={clsx(classes.buttonGroup)}
              variant="text"
              color="primary"
              aria-label="text primary button group"
            >
              {ticket.status === "pending" &&
                (ticket.queueId === null || ticket.queueId === undefined) && (
                  <Tooltip title={`${i18n.t("ticketsList.buttons.accept")}`}>
                    <Button
                      className={classes.button}
                      aria-label="accept"
                      onClick={(e) => handleOpenAcceptTicketWithouSelectQueue()}
                    >
                      <Done className={classes.iconSize} />
                    </Button>
                  </Tooltip>
                )}
              {ticket.status === "pending" && ticket.queueId !== null && (
                <Tooltip title={`${i18n.t("ticketsList.buttons.accept")}`}>
                  <Button
                    className={classes.button}
                    aria-label="accept"
                    onClick={(e) => handleAcepptTicket(ticket.id)}
                  >
                    <Done className={classes.iconSize} />
                  </Button>
                </Tooltip>
              )}
              {(ticket.status === "pending" ||
                ticket.status === "open" ||
                ticket.status === "group") && (
                <Tooltip title={`${i18n.t("ticketsList.buttons.transfer")}`}>
                  <Button
                    className={classes.button}
                    aria-label="transfer"
                    onClick={handleOpenTransferModal}
                  >
                    <SwapHoriz className={classes.iconSize} />
                  </Button>
                </Tooltip>
              )}
              {(ticket.status === "open" || ticket.status === "group") && (
                <Tooltip title={`${i18n.t("ticketsList.buttons.closed")}`}>
                  <Button
                    className={classes.button}
                    aria-label="closed"
                    onClick={(e) => handleCloseTicket(ticket.id)}
                  >
                    <HighlightOff className={classes.iconSize} />
                  </Button>
                </Tooltip>
              )}
              {(ticket.status === "pending" || ticket.status === "lgpd") &&
                (user.userClosePendingTicket === "enabled" ||
                  user.profile === "admin") && (
                  <Tooltip title={`${i18n.t("ticketsList.buttons.ignore")}`}>
                    <Button
                      className={classes.button}
                      aria-label="ignore"
                      onClick={(e) => handleCloseIgnoreTicket(ticket.id)}
                    >
                      <HighlightOff className={classes.iconSize} />
                    </Button>
                  </Tooltip>
                )}
              {ticket.status === "closed" && (
                <Tooltip title={`${i18n.t("ticketsList.buttons.reopen")}`}>
                  <Button
                    className={classes.button}
                    aria-label="reopen"
                    onClick={
                      ticket.queueId === null || ticket.queueId === undefined
                        ? (e) => handleOpenAcceptTicketWithouSelectQueue()
                        : (e) => handleAcepptTicket(ticket.id)
                    }
                  >
                    <Replay className={classes.iconSize} />
                  </Button>
                </Tooltip>
              )}
            </ButtonGroup>
          </div>
        </ListItemSecondaryAction>
      </ListItemButton>
    </React.Fragment>
  );
};

export default TicketListItemCustom;
