import React, { useState, useContext, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings from "../../hooks/useSettings";
import usePlans from "../../hooks/usePlans";
import { toast } from "react-toastify";
import { openApi } from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import "./style.css";
import ColorModeContext from "../../layout/themeContext"; // Importar o contexto do tema

const Login = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [allowSignup, setAllowSignup] = useState(false);
  const { getPublicSetting } = useSettings();
  const { handleLogin } = useContext(AuthContext);
  const { getPlanList } = usePlans();
  const [plans, setPlans] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const history = useHistory();

  const { appLogoLight, appLogoDark, primaryColorLight, primaryColorDark, mode } = useContext(ColorModeContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const values = Object.fromEntries(formData.entries());
    try {
      await openApi.post("/auth/signup", values);
      toast.success(i18n.t("signup.toasts.success"));
      history.push("/login");
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    getPublicSetting("allowSignup")
      .then((data) => {
        setAllowSignup(data === "enabled");
      })
      .catch((error) => {
        console.log("Error reading setting", error);
      });

    const fetchData = async () => {
      const planList = await getPlanList({ listPublic: "false" });
      setPlans(planList);
    };

    fetchData();
  }, [getPublicSetting, getPlanList]);

  return (
    <div className={`container ${isActive ? "active" : ""}`} style={{ borderColor: mode === 'light' ? primaryColorLight : primaryColorDark }}>
      <div className="toggle-container">
        <div className="toggle">
          <div className="toggle-panel toggle-left">
            <h1>Seja bem-vindo(a)</h1>
            <p>Caso já tenha uma conta, faça o seu login.</p>
            <button className="hidden" onClick={() => setIsActive(false)}>
              Entrar
            </button>
          </div>
          <div className="toggle-panel toggle-right">
            <h1>Simples e acessível</h1>
            <p>Caso ainda não tenha cadastro...</p>
            <button className="hidden" onClick={() => setIsActive(true)}>
              Cadastrar-se
            </button>
          </div>
        </div>
      </div>
      <div className="form-container sign-in">
        <form onSubmit={handleSubmitLogin}>
          <h1>Acesso</h1>
          <img src={mode === 'light' ? appLogoLight : appLogoDark} alt="Logo" className="logoImg" />
          <span>Inicie sua jornada...</span>
          <input
            type="email"
            placeholder="Email"
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            required
            autoComplete="email"
            autoFocus
          />
          <input
            type="password"
            placeholder="Senha"
            name="password"
            value={user.password}
            onChange={handleChangeInput}
            required
            autoComplete="current-password"
          />
          <button type="submit" className="login-button">
            Acessar
          </button>
          {allowSignup && (
            <div className="signup-link">
              <RouterLink to="/signup">Não tem uma conta? Registre-se</RouterLink>
            </div>
          )}
        </form>
      </div>
      <div className="form-container sign-up">
        <form onSubmit={handleSignUp}>
          <h1>Cadastro</h1>
          <span>use seu melhor email...</span>
          <input type="text" placeholder="Empresa" name="companyName" required />
          <input type="text" placeholder="Nome" name="name" required />
          <input type="email" placeholder="Email" name="email" required />
          <input type="password" placeholder="Senha" name="password" required />
          <input type="text" placeholder="Telefone" name="phone" required />
          <select name="planId" required>
            <option value="">Plano</option>
            {plans.map((plan) => (
              <option key={plan.id} value={plan.id}>
                {plan.name} - R$ {plan.amount}
              </option>
            ))}
          </select>
          <button type="submit" className="signup-button">
            Cadastrar-se
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
